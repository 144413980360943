import { authRequest } from './api/auth';

class KnowledgeLibraryService {
    list() {
        return authRequest.get(`/chatgpt/upgpt/knowledge_library/`);
    }
    select_list(){
        return authRequest.get(`/chatgpt/upgpt/knowledge_selectlist/`);
    }
    create(data) {
        return authRequest.post(`/chatgpt/upgpt/knowledge_library/`,data);
    }
    detail(id){
        return authRequest.get(`/chatgpt/upgpt/knowledge_library_change/${id}/`);
    }
    set_detail_bots(data){
        return authRequest.put(`/chatgpt/upgpt/knowledge_lib_linked_bot_change/${data.id}/`,data);
    }
    edit(data){
        return authRequest.put(`/chatgpt/upgpt/knowledge_library_change/${data.id}/`,data);
    }
    delete(id){
        return authRequest.delete(`/chatgpt/upgpt/knowledge_library_change/${id}/`);
    }
    clear_knowledge(id){
        return authRequest.post(`/chatgpt/upgpt/clear_knowledge/${id}/`);
    }
    getknowledgedetail(id){
        return authRequest.post(`/chatgpt/upgpt/getknowledgedetail/${id}/`);
    }
    uploadqafilebyacs(data,file){
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('file', file);
        formData.append('knowledge_lib_id', data.knowledge_lib_id);
        formData.append('name', data.name);
        return authRequest.post(`/chatgpt/upgpt/uploadqafilebyacs/`,formData,config);
    }
    uploadFolder(data,file){
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('file', file);
        formData.append('knowledge_lib_id', data.knowledge_lib_id);
        formData.append('name', data.name);
        return authRequest.post(`/chatgpt/upgpt/uploadfolderbyacs/`,formData,config);
    }
    uploadFile(data,file){
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('file', file);
        formData.append('knowledge_lib_id', data.knowledge_lib_id);
        formData.append('name', data.name);
        return authRequest.post(`/chatgpt/upgpt/uploadfilebyacs/`,formData,config);
    }
    websiteanalysis(data){
        return authRequest.post(`/chatgpt/upgpt/webanalysisbyacs`,data);
    }
    getWebList(id){
        return authRequest.get(`/chatgpt/upgpt/setweburl/?knowledge_lib_id=${id}`);
    }
    addWebList(data){
        return authRequest.post(`/chatgpt/upgpt/setweburl/`,data);
    }
    editWebList(id,data){
        return authRequest.put(`/chatgpt/upgpt/changeweburl/${id}`,data);
    }
    deleteWebList(id){
        return authRequest.delete(`/chatgpt/upgpt/changeweburl/${id}`);
    }
    delete_single_knowledge(id){
        return authRequest.delete(`/chatgpt/upgpt/delete_single_knowledge/${id}`);
    }
    downloadtxt(filename) {
        return authRequest.post(`/chatgpt/upgpt/downloadtxt/`,filename);
    }
}
export default new KnowledgeLibraryService();