import { authRequest } from './api/auth';

class AiBotsService {
    select_list() {
        return authRequest.get(`/chatgpt/upgpt/aibotselectlist/`);
    }
    list() {
        return authRequest.get(`/chatgpt/upgpt/aibotlist/`);
    }
    active(id,data) {
        return authRequest.put(`/chatgpt/upgpt/aibotactive/${id}/`,data);
    }
    create(data) {
        return authRequest.post(`/chatgpt/upgpt/aibotlist/`,data);
    }
    detail(id) {
        return authRequest.get(`/chatgpt/upgpt/aibotdetail/${id}`);
    }
    edit(id,data) {
        return authRequest.put(`/chatgpt/upgpt/aibotdetail/${id}/`,data);
    }
    delete(id) {
        return authRequest.delete(`/chatgpt/upgpt/aibotdetail/${id}`);
    }
    get_upchat_inbox() {
        let data = {
            "api_type" : "get_inboxes_list",
        }
        return authRequest.post(`/chatwoot/gpt/`,data);
    }
}
export default new AiBotsService();